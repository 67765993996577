//土耳其
export const tr = {
    //Gezinti çubuğu
    'n1': 'Ana Sayfa',
    'n2': 'Sipariş',
    'n3': 'İşlem',
    'n4': 'Takım',
    'n5': 'benim',
    //istem sınıfı
    't1': 'Bir hata oluştu',
    't2': 'Kopyalama başarılı',
    't3': 'Lütfen bilgileri eksiksiz doldurunuz',
    't4': 'Yükleniyor...',
    't5': 'Giriş başarılı',
    't6': "Gönderilmek için puanın 3'ten büyük olması gerekir",
    't7': 'Gönderim başarılı',
    't8': 'Yetersiz bakiye, alınamıyor',
    't9': 'Gönderim başarısız oldu',
    't10': 'Lütfen önce adresi bağlayın; kişisel merkez sayfasına yönlendirileceksiniz',
    't11': 'Adres sınırlandı, değiştirmek için lütfen müşteri hizmetleriyle iletişime geçin',
    't12': 'Değişiklik başarılı',
    't13': 'Değiştirme başarısız oldu',
    't14': 'Şifrenizi değiştirmek için lütfen müşteri hizmetleriyle iletişime geçin',
    't15': 'Şifre biçimi hatası',
    't16': 'Para çekme başarılı',
    't17': 'Maksimum para çekme miktarı',
    't18': 'Minimum para çekme',
    't19': 'İki şifre tutarsız',
    't20': 'Bildirim',
    't21': 'Geçici olarak atlanamıyor',
    't22': 'Şifre biçimi hatası',
    't23': 'Kayıt başarılı',
    't24': 'Lütfen alan kodunu girin',
    't25': 'Artık yok',
    //üye olmak
    'l1': 'Dil',
    'l2': 'Lütfen hesap numaranızı girin',
    'l3': 'Lütfen şifreyi girin',
    'l4': 'hatırla',
    'l5': 'Giriş Yap',
    'l6': 'Kayıta git',
    'l7': 'Hesap yok',
    'l8': 'Cep telefonu numarası',
    'l9': 'takma ad',
    'l10': 'şifre',
    'l11': 'Şifreyi onayla',
    'l12': 'davetiye kodu',
    'l13': 'Kayıt Ol',
    'l14': 'Zaten bir hesabınız var',
    'l15': 'Giriş yapmaya git',
    'l16': 'Lütfen 6-16 haneli şifrenizi giriniz',

    //yardım
    'bz1': 'Yardım',
    'bz2': 'SSS',
    'bz3': 'Ticaret kuralları',
    'bz4': 'Para nasıl çekilir',
    'bz5': 'Nasıl şarj edilir',
    'bz6': '1.TiktokShop nedir?',
    'bz7': 'TiktokShop, dünyanın dört bir yanındaki TiktokShop çevrimiçi mağaza satıcılarının daha fazla sipariş satışı elde etmesine ve TiktokShop çevrimiçi mağazalarının tarama verilerini artırmasına yardımcı olan bir pazarlama ve tanıtım şirketidir. TiktokShop, satıcılar ve tüketiciler arasında üçlü, kârlı bir pazarlama ve tanıtım modeli kurmaya kararlıyız. En son P2P blok zinciri teknolojisiyle birleştirildiğinde tüketiciler ve satıcılar USDT (TRC20, ERC20) aracılığıyla hızlı bir şekilde bağlanır. Kayıtlı kullanıcılar siparişlerden komisyon alırken, satıcılar da mağazalarının satış verilerini artırabilir. İnternet blockchain modelindeki en son kar modelidir! ',
    'bz8': '2.TiktokShop nasıl çalışır?',
    'bz9': "TiktokShop'tan gelen günlük geri bildirimler, iyileştirilmesi gereken ürünlerin TiktokShop'ta satıldığını gösteriyor. Kullanıcıların yalnızca siparişe kolayca tıklaması gerekiyor ve sistem otomatik olarak bir sipariş aboneliği oluşturuyor. Kullanıcılar sipariş tutarını blockchain USDT aracılığıyla öder ve günlük komisyon alırlar. ",
    'bz10': '3. Aynı para biriminin fiyatları iki işlemde neden farklı? ',
    'bz11': 'Fiyat farklılıkları, döviz transferlerinin hızı, ağ koşulları, para birimi erişim kısıtlamaları, farklı bölgelerdeki insanlar tarafından para biriminin tanınması ve hatta işlem çiftleri dahil olmak üzere, para birimlerinin serbest dolaşımına yardımcı olmayan tüm faktörlerden kaynaklanmaktadır. Değişim türleri, işlemler vb. tarafından sağlanır. Bu nedenle aynı para biriminin farklı işlemlerde fiyat farklılıkları olabiliyor. ',
    'bz12': '4. Yatırım karı mı? ',
    'bz13': 'Aldığınız ürün fiyatı ne kadar yüksek olursa sipariş karı da o kadar yüksek olur. Aynı zamanda TiktokShop, büyük komisyon siparişlerini rastgele dağıtır. ',
    'bz14': 'Sistem her gün otomatik olarak 60 ürün siparişi oluşturup kullanıcılara dağıtıyor. Kullanıcılar her sipariş için ödemeyi USDT aracılığıyla tamamlıyor ve %0,06 komisyon alıyor. Büyük komisyon emirleri rastgele atanır. ',
    'bz15': 'USDT 60 emirden sonra çekilebilir. (60 adet siparişin tamamlanmaması halinde, günün kalan siparişlerine kadar sistem otomatik olarak duracaktır)',
    'bz16': 'Her hesap yalnızca bir USDT hesabına bağlanabilir. Bir hata oluşursa lütfen müşteri hizmetleriyle iletişime geçin',
    'bz17': 'Günlük emirlerin teslimatı tamamlandıktan sonra normal şekilde para çekebilirsiniz. Çekim başladıktan sonra 24 saat içinde elinize ulaşacaktır ve para çekme limiti yoktur',
    'bz18': "Lütfen yeniden yüklemeden önce para çekme adresinizi (TRC-20'yi destekler) USDT adresinizi bağlayın. Uygulamada veya web sayfasında Yeniden Yükle'ye tıklayabilir ve yeniden yükleme için kullandığınız blockchain'i (TRC-20) seçebilirsiniz. ",
    'bz19': "İlgili hesaplar arasında para yükleme veya transferine izin verilmez. Bu kötü niyetli emir kapma davranışıdır ve platformun çalışma kurallarını ciddi şekilde etkiler. Böyle bir davranışın tespit edilmesi durumunda sistem, hesabı derhal donduracak ve çalışmayı diskalifiye edecektir.",
    'bz20': "İlişkili hesapların bir sonraki günün sipariş görevlerini güncellemeye devam edebilmeleri için her günün hesaplarındaki sipariş görevlerini tamamlamaları gerekir. İlişkili hesaplar arasında tamamlanmamış bir görev varsa, sipariş görevlerinin güncellenmesi durdurulacaktır. tüm sipariş görevleri tamamlanana kadar Siparişi güncelle görevi",
    "bz21": "İlişkili hesaplar arasında yükleme veya transfer yapılmasına izin verilmez. Bu, kötü niyetli sipariş kapma davranışıdır ve platformun çalışma kurallarını ciddi şekilde etkiler. Böyle bir davranış tespit edilirse sistem, hesaba para cezası keser. İlk ceza, hesap fonunun %15'idir. Tekrarlanan kötü niyetli sipariş kapma davranışı sizi doğrudan çalışmadan diskalifiye edecektir.",
    //modül metni
    //Ortak kelimeler
    'ty1': 'Tamam',
    'ty2': 'İptal',
    'ty3': 'Gönder',
    'ty4': 'adres',
    //ön Sayfa
    's1': 'Kümülatif gelir',
    's2': 'Hizmet',
    's3': 'Hakkımızda',
    's4': 'Yardım',
    's5': 'Ortak',
    's6': 'Hizmetim',
    //Emir
    'd1': 'Bitmemiş',
    'd2': 'Tamamlandı',
    'd3': 'Henüz veri yok',
    'd4': 'tek sayı',
    'd5': 'İşlem zamanı',
    'd6': 'miktar',
    'd7': 'Geçerli görev sayısı',
    'd8': 'Beklenen getiri',
    'd9': 'Daha fazlasına ihtiyaç var',
    'd10': 'Derecelendirme',
    //ticaret
    'j1': 'Hesap bakiyesi',
    'j2': 'Ticaret tanıtımı',
    'j3': "TiktokShop, TiktokShop tarafından satılan ve iyileştirilmesi gereken ürünleri göstermek için her gün TiktokShop'un geri bildirimlerini kullanır. Kullanıcıların yalnızca siparişe tıklaması yeterlidir; sistem otomatik olarak bir sipariş imzası oluşturacaktır. Kullanıcılar sipariş tutarını USDT blok zinciri aracılığıyla öder ve her seferinde %0,6'dan fazla komisyon alabilir ve sistem büyük ödül siparişlerini rastgele dağıtır. ",
    'j4': 'Eşleştirmeyi başlat',
    'j5': 'Bugünkü kazançlar',
    'j6': 'Numara tamamlandı',
    'j7': 'Toplam görev sayısı',
    //takım
    'td1': 'Denge',
    'td2': 'komisyon',
    'td3': 'Al',
    'td4': 'Toplam kişi sayısı',
    'td5': 'kullanıcı',
    'td6': 'katkı',
    'td7': 'Miktar',
    'td8': 'durum',
    //Kişisel merkez
    'g1': 'Çekilme',
    'g2': 'Şarj Et',
    'g3': 'Arkadaşlarını davet et',
    'g4': 'Şimdi davet et',
    'g5': 'Arkadaşınızın kayıt olduktan sonra elde ettiği her kâr için, buna karşılık gelen oranda komisyon alacaksınız',
    'g6': 'Kişisel bilgiler',
    'g7': 'Fon ayrıntıları',
    'g8': 'Şarj kaydı',
    'g9': 'Para çekme kaydı',
    'g10': 'Dil',
    'g11': 'Oturumu kapat',
    'g12': 'Çıkış yapmak istediğinizden emin misiniz?',
    //Kullanıcı bilgisi
    'yh1': 'Kullanıcı bilgisi',
    'yh2': 'Değiştir',
    'yh3': 'ayar',
    'yh4': 'telefon',
    'yh5': 'Çıkış yapmak istediğinizden emin misiniz?',
    'yh6': 'Orijinal şifre',
    'yh7': 'Yeni şifre',
    //Detaylar
    'mx1': 'Komisyon tahsilatı',
    'mx2': 'kar',
    'mx3': 'Para çekme reddedildi',
    'mx4': 'zaman',
    'mx5': 'Şarj başarılı',
    'mx6': 'Reddedildi',
    'mx7': 'beklemede',
    'mx8': 'Para çekme adresi',
    'mx9': 'Kullanılabilir bakiye',
    'mx10': 'Gerçek varış',
    'mx11': 'Lütfen çekim tutarını giriniz',
    //doldur
    'cz1': "Para yatırma adresi yalnızca ERC20'yi destekliyor ve minimum para yatırma tutarı 20USDT'dir",
    'cz2': 'Kopyala',
    'cz3': 'Fiat para birimi seçimi',
    'cz4': 'ödeme',
    'cz5': "Para yatırma adresi yalnızca TRC20-USDT'yi desteklemektedir ve minimum para yatırma tutarı 20USDT'dir",
    'cz6': "Para yatırma adresi yalnızca ERC20'yi destekliyor ve minimum para yatırma tutarı 20USDT'dir",
    'cz1': "Para yatırma adresi yalnızca ERC20'yi destekliyor ve minimum para yatırma tutarı 20USDT'dir",
    'cz1': "Para yatırma adresi yalnızca ERC20'yi destekliyor ve minimum para yatırma tutarı 20USDT'dir",
    //alan
    'qy1': 'alan kodu',
    'yq1': 'Arkadaşlarını davet et',
    'yq2': 'davetiye kodu',
    'yq3': 'Arkadaşlarınızı davet edin ve büyük avantajlardan yararlanın',
    'yq4': 'Git ve davet et',
}

export const fa = {
    "n1": "صفحه اول",
    "n2": "ترتیب",
    "n3": "تجارت",
    "n4": "تیم",
    "n5": "معدن",
    "t1": "مشکلی پیش آمد",
    "t2": "با موفقیت کپی کنید",
    "t3": "لطفاً اطلاعات را به طور کامل پر کنید",
    "t4": "بارگیری ...",
    "t5": "با موفقیت وارد شوید",
    "t6": "نمره باید قبل از ارسال بیشتر از 3 باشد",
    "t7": "تسلیم موفق",
    "t8": "تعادل کافی ، جمع آوری نمی شود",
    "t9": "تسلیم نشد",
    "t10": "لطفاً ابتدا آدرس را وصل کنید و صفحه مرکز شخصی به صفحه مرکز شخصی هدایت می شود.",
    "t11": "آدرس محدود شده است ، لطفاً برای تغییر آن با خدمات مشتری تماس بگیرید",
    "t12": "اصلاح موفقیت آمیز بود",
    "t13": "اصلاح انجام نشد",
    "t14": "لطفاً برای تغییر رمز عبور خود با خدمات مشتری تماس بگیرید",
    "t15": "خطای قالب رمز عبور",
    "t16": "عقب نشینی موفقیت آمیز",
    "t17": "برای برداشت پول نقد",
    "t18": "حداقل برداشت",
    "t19": "رمزهای عبور دو بار متناقض هستند",
    "t20": "اشاره",
    "t21": "فعلاً نمی تواند پرش کند",
    "t22": "خطای قالب رمز عبور",
    "t23": "با موفقیت ثبت شده است",
    "t24": "لطفا کد منطقه را وارد کنید",
    "t25": "هنوز بیشتر نیست",
    "l1": "زبان",
    "l2": "لطفا شماره حساب خود را وارد کنید",
    "l3": "لطفا رمز ورود خود را وارد کنید",
    "l4": "یادآوری کردن",
    "l5": "وارد کردن",
    "l6": "به ثبت نام بروید",
    "l7": "بدون حساب",
    "l8": "شماره تلفن",
    "l9": "نام مستعار",
    "l10": "رمز",
    "l11": "تأیید رمز عبور",
    "l12": "رمز دعوت",
    "l13": "ثبت نام",
    "l14": "در حال حاضر یک حساب کاربری دارید",
    "l15": "برای ورود به سیستم بروید",
    "l16": "لطفاً یک رمز عبور 6-16 رقمی وارد کنید",
    "bz1": "کمک",
    "bz2": "سوالات متداول",
    "bz3": "قوانین تجارت",
    "bz4": "نحوه برداشت پول",
    "bz5": "چگونه بالا برویم",
    "bz6": "1. Tiktokshop چیست؟",
    "bz7": "Tiktokshop یک شرکت ارتقاء بازاریابی است که به بازرگانان فروشگاه آنلاین Tiktokshop در سراسر جهان کمک می کند تا سفارش بیشتری را برای فروش سفارش داده و داده های مرور را برای فروشگاه آنلاین Tiktokshop افزایش دهند. ما متعهد به ایجاد یک مدل بازاریابی و تبلیغ سودآور سه حزب بین Tiktokshop و بازرگانان و مصرف کنندگان هستیم. با ترکیب جدیدترین فناوری Blockchain P2P ، به سرعت مصرف کنندگان و بازرگانان را از طریق USDT (TRC20 ، ERC20) به هم وصل می کند. کاربران ثبت نام شده می توانند کمیسیون ها را در سفارشات دریافت کنند ، در حالی که بازرگانان می توانند داده های فروش فروشگاه را افزایش دهند. این آخرین مدل سود در مدل blockchain اینترنت است!",
    "bz8": "2. چگونه Tiktokshop کار می کند؟",
    "bz9": "از طریق بازخورد روزانه Tiktokshop ، این نشان می دهد که هنگام فروش محصولاتی که نیاز به پیشرفت در Tiktokshop دارند ، کاربران فقط باید به راحتی روی سفارش کلیک کنند و سیستم به طور خودکار اشتراک های سفارش را تولید می کند. کاربران مبلغ سفارش را از طریق blockchain USDT پرداخت می کنند و کمیسیون های روزانه دریافت می کنند.",
    "bz10": "3. چرا در دو معاملات تفاوت در قیمت های همان ارز وجود دارد؟",
    "bz11": "اختلاف قیمت توسط همه عواملی ایجاد می شود که منجر به گردش رایگان ارزها ، از جمله سرعت انتقال ارز ، شرایط شبکه ، محدودیت های دسترسی به ارز ، تشخیص ارز از ارز توسط مناطق مختلف و حتی انواع جفت های معامله ارائه شده توسط مبادلات و معاملات می شود. بنابراین ، همان ارز ممکن است اختلاف قیمت را در معاملات مختلف ایجاد کند.",
    "bz12": "4. سود سرمایه گذاری؟",
    "bz13": "هرچه قیمت کالای مورد نظر بیشتر باشد ، سود شما در سفارش بیشتر می شود. در همین حال ، Tiktokshop به طور تصادفی دستورات بزرگ کمیسیون را اختصاص می دهد.",
    "bz14": "هر روز ، سیستم به طور خودکار 60 سفارش محصول را برای کاربران توزیع و توزیع می کند. سفارشات کمیسیون بزرگ به طور تصادفی اختصاص می یابد.",
    "bz15": "USDT می تواند پس از 60 سفارش پول نقد را پس بگیرد. (اگر 60 سفارش تکمیل نشود ، سیستم به طور خودکار به حجم سفارش باقی مانده روز متوقف می شود)",
    "bz16": "هر حساب فقط در صورت بروز خطایی می تواند به یک حساب USDT محدود شود.",
    "bz17": "پس از اتمام تحویل سفارش روزانه ، می توانید پول را به طور عادی پس بگیرید و طی 24 ساعت پس از شروع عقب نشینی وارد شوید و محدودیت برداشتی وجود ندارد.",
    "bz18": "قبل از شارژ ، لطفاً آدرس USDT خود را به آدرس USDT (پشتیبانی از TRC-20) وصل کنید.",
    'bz19': "شارژ مجدد یا انتقال پول بین حساب‌های مرتبط مجاز نیست. این رفتار بداخلاقی برای گرفتن سفارش است و به طور جدی قوانین عملکرد پلت فرم را تحت تاثیر قرار می‌دهد. در صورت مشاهده چنین رفتاری، سیستم بلافاصله حساب را مسدود کرده و کار را رد صلاحیت می‌کند.",
    "bz20": "حساب مربوطه باید هر روز قبل از ادامه به روزرسانی وظایف سفارش در روز بعد ، کارهای سفارش را در حساب انجام دهد.",
    "bz21": "شارژ مجدد یا انتقال بین حساب های مرتبط مجاز نیست. این رفتار بداخلاقی برای گرفتن سفارش است و به طور جدی قوانین عملیاتی پلتفرم را تحت تاثیر قرار می دهد. در صورت یافتن چنین رفتاری، سیستم حساب را جریمه می کند. اولین جریمه 15٪ از وجوه حساب است. رفتار مکرر گرفتن سفارش مخرب مستقیماً شما را از کار محروم می کند.",
    "ty1": "مطمئن",
    "ty2": "لغو کردن",
    "ty3": "ارسال کردن",
    "ty4": "خطاب",
    "s1": "درآمد تجمعی",
    "s2": "خدمت کردن",
    "s3": "درباره ما",
    "s4": "کمک",
    "s5": "شرکای",
    "s6": "خدمات من",
    "d1": "تکمیل نشده",
    "d2": "کامل",
    "d3": "هنوز اطلاعاتی وجود ندارد",
    "d4": "یک شماره",
    "d5": "زمان تجارت",
    "d6": "مقدار",
    "d7": "تعداد کنونی کارها",
    "d8": "درآمد مورد انتظار",
    "d9": "نیاز",
    "d10": "امتیاز",
    "j1": "تراز حساب",
    "j2": "معرفی معامله",
    "j3": "Tiktokshop هر روز از بازخورد Tiktokshop استفاده می کند تا محصولات فروخته شده توسط Tiktokshop را که نیاز به پیشرفت دارند ، به نمایش بگذارد. کاربر فقط باید روی سفارش کلیک کند و سیستم به طور خودکار امضای سفارش را تولید می کند. کاربران مبلغ سفارش را از طریق blockchain USD پرداخت می کنند و هر بار بیش از 0.6 ٪ کمیسیون دریافت می کنند.",
    "j4": "شروع تطبیق",
    "j5": "درآمد امروز",
    "j6": "شماره تکمیل شده",
    "j7": "تعداد کل کارها",
    "td1": "تعادل",
    "td2": "راهق",
    "td3": "دریافت کردن",
    "td4": "تعداد کل مردم",
    "td5": "کاربر",
    "td6": "مشارکت کردن",
    "td7": "مقدار",
    "td8": "حالت",
    "g1": "عقب نشینی",
    "g2": "بالا بردن",
    "g3": "دوستان را دعوت کنید",
    "g4": "اکنون دعوت کنید",
    "g5": "شما برای هر سودی که پس از ثبت نام یک دوست کسب می کنید ، نسبت مربوط به کمیسیون دریافت خواهید کرد",
    "g6": "اطلاعات شخصی",
    "g7": "جزئیات بودجه",
    "g8": "رکورد شارژ",
    "g9": "سابقه برداشت",
    "g10": "زبان",
    "g11": "بیرون رفتن",
    "g12": "آیا مطمئناً از سیستم خارج می شوید؟",
    "yh1": "اطلاعات کاربر",
    "yh2": "تجدید نظر کردن",
    "yh3": "تنظیم کردن",
    "yh4": "تلفن",
    "yh6": "رمز عبور اصلی",
    "yh7": "رمزعبور جدید",
    "mx1": "کمیسیون دریافت",
    "mx2": "درآمد",
    "mx3": "امتناع از عقب نشینی",
    "mx4": "زمان",
    "mx5": "با موفقیت شارژ کنید",
    "mx6": "رد شده",
    "mx7": "درنده",
    "mx8": "آدرس عقب نشینی",
    "mx9": "تعادل موجود",
    "mx10": "رسید واقعی",
    "mx11": "لطفاً مبلغ برداشت را وارد کنید",
    "cz2": "کپی کردن",
    "cz3": "انتخاب ارز فیلیپینی",
    "cz4": "پرداختن",
    "cz5": "آدرس سپرده فقط از TRC20-USDT پشتیبانی می کند و حداقل مبلغ سپرده 20USDT است.",
    "cz6": "آدرس سپرده فقط از ERC20 پشتیبانی می کند ، حداقل مبلغ سپرده 20USDT است",
    "cz1": "آدرس سپرده فقط از ERC20 پشتیبانی می کند ، حداقل مبلغ سپرده 20USDT است",
    "qy1": "رمز منطقه",
    "yq1": "دوستان را دعوت کنید",
    "yq2": "رمز دعوت",
    "yq3": "دوستان را دعوت کنید و از سود زیادی لذت ببرید",
    "yq4": "برو و دعوت کن"
  }
  
export const fi = {
    "n1": "etusivu",
    "n2": "Tilata",
    "n3": "kauppa",
    "n4": "joukkue",
    "n5": "minun",
    "t1": "Jotain meni pieleen",
    "t2": "Kopioida onnistuneesti",
    "t3": "Täytä tiedot kokonaisuudessaan",
    "t4": "lataaminen…",
    "t5": "Kirjaudu sisään onnistuneesti",
    "t6": "Pistemäärän on oltava suurempi kuin 3 ennen lähettämistä",
    "t7": "Suunnitteleminen onnistunut",
    "t8": "Riittämätöntä tasapainoa ei voida kerätä",
    "t9": "Lähetys epäonnistui",
    "t10": "Sitoudu ensin osoite, ja henkilökohtainen keskuksen sivu ohjataan henkilökohtaiseen keskuksen sivulle.",
    "t11": "Osoite on sidottu, ota yhteyttä asiakaspalveluun muokataksesi sitä",
    "t12": "Muutos oli onnistunut",
    "t13": "Muutos epäonnistui",
    "t14": "Ota yhteyttä asiakaspalveluun muokataksesi salasanasi",
    "t15": "Salasanamuotovirhe",
    "t16": "Onnistunut vetäytyminen",
    "t17": "Nosta käteisellä",
    "t18": "Vähimmäispoisto",
    "t19": "Salasanat ovat epäjohdonmukaisia ​​kahdesti",
    "t20": "vihje",
    "t21": "Ei voi hypätä toistaiseksi",
    "t22": "Salasanamuotovirhe",
    "t23": "Rekisteröity onnistuneesti",
    "t24": "Anna suuntanumero",
    "t25": "Ei vielä enää",
    "l1": "kieli",
    "l2": "Anna tilinumero",
    "l3": "Anna salasanasi",
    "l4": "muistaa",
    "l5": "Kirjautua sisään",
    "l6": "Mennä ilmoittautumiseen",
    "l7": "Ei tiliä",
    "l8": "Puhelinnumero",
    "l9": "Lempinimi",
    "l10": "salasana",
    "l11": "Vahvista salasana",
    "l12": "Kutsukoodi",
    "l13": "rekisteröidä",
    "l14": "On jo tili",
    "l15": "Mennä kirjautumaan sisään",
    "l16": "Anna 6-16-numeroinen salasana",
    "bz1": "auttaa",
    "bz2": "Usein kysyttyjä kysymyksiä",
    "bz3": "Kaupankäyntisäännöt",
    "bz4": "Kuinka nostaa rahaa",
    "bz5": "Kuinka täydentää",
    "bz6": "1. Mikä on Tiktokshop?",
    "bz7": "Tiktokshop on markkinoinnin edistämisyritys, joka auttaa Tiktokshop -verkkokaupan kauppiaita ympäri maailmaa saamaan enemmän tilausmyyntiä ja lisäämään Tiktokshop -verkkokaupan selaustietoja. Olemme sitoutuneet perustamaan kolmen osapuolen kannattavan markkinointi- ja myynninedistämismallin Tiktokshopin ja kauppiaiden ja kuluttajien välille. Yhdistämällä uusimman P2P -blockchain -tekniikan, yhdistävät kuluttajat ja kauppiaat nopeasti USDT: n (TRC20, ERC20) kautta. Rekisteröityneet käyttäjät voivat vastaanottaa tilauksia koskevia palkkioita, kun taas kauppiaat voivat lisätä myymälöiden myyntitietoja. Se on Internet -blockchain -mallin uusin voittomalli!",
    "bz8": "2.Miten tiktokshop toimii?",
    "bz9": "Tiktokshopin päivittäisen palautteen kautta se osoittaa, että myymällä tuotteita, jotka tarvitsevat parannuksia Tiktokshopissa, käyttäjien on vain napsautettava tilausta helposti, ja järjestelmä tuottaa tilaustilaukset automaattisesti. Käyttäjät maksavat tilausmäärät Blockchain USDT: n kautta ja saavat päivittäiset palkkiot.",
    "bz10": "3. Miksi saman valuutan hinnoilla on eroja kahdessa tapahtumassa?",
    "bz11": "Hintaerot johtuvat kaikista tekijöistä, jotka eivät edistä valuuttojen vapaata kiertoa, mukaan lukien valuutansiirron nopeus, verkon olosuhteet, valuutan pääsyrajoitukset, ihmisten tunnustama valuutan eri alueilla ja jopa vaihtojen ja tapahtumien tarjoamien transaktioparien tyypit. Siksi sama valuutta voi luoda hintaeroja eri liiketoimissa.",
    "bz12": "4. sijoitusvoitto?",
    "bz13": "Mitä korkeampi saamasi tuotteen hinta, sitä korkeampi voitto on tilauksessa. Samaan aikaan Tiktokshop antaa satunnaisesti suuret komission määräykset.",
    "bz14": "Joka päivä järjestelmä tuottaa ja jakaa 60 tuotetilausta käyttäjille. Suuret komission tilaukset on satunnaisesti jaettu.",
    "bz15": "USDT voi nostaa käteisellä 60 tilauksen jälkeen. (Jos 60 tilausta ei ole saatu päätökseen, järjestelmä pysähtyy automaattisesti päivän jäljellä olevaan tilavuuteen)",
    "bz16": "Jokainen tili voidaan sitoutua vain yhteen USDT -tiliin.",
    "bz17": "Päivittäisen tilaustoimituksen suorittamisen jälkeen voit nostaa rahaa normaalisti ja saapua 24 tunnin kuluessa noston aloittamisen jälkeen, eikä nostorajoituksia ole.",
    "bz18": "Ennen kuin lataus, sido nostoosoitteesi (tukee TRC-20) USDT-osoitetta. Voit napsauttaa sovellusta tai verkkosivua ladataksesi ja valitaksesi latauslaitteen Blockchain (TRC-20).",
    'bz19': "Rahan lataaminen tai siirtäminen toisiinsa liittyvien tilien välillä ei ole sallittua. Tämä on haitallista tilauskaappauskäyttäytymistä ja vaikuttaa vakavasti alustan toimintasääntöihin. Jos tällainen käyttäytyminen havaitaan, järjestelmä jäädyttää välittömästi tilin ja hylkää työn.",
    "bz20": "Aiheeseen liittyvä tili on suoritettava tilin tilaustehtävät joka päivä ennen tilaustehtävien päivittämistä seuraavana päivänä.",
    "bz21": "Lataukset tai siirrot eivät ole sallittuja yhdistettyjen tilien välillä. Tämä on haitallista tilausten kaappauskäyttäytymistä ja vaikuttaa vakavasti alustan toimintasääntöihin. Jos tällainen käyttäytyminen havaitaan, järjestelmä sakottaa tiliä. Ensimmäinen sakko on 15 % tilin varoista. Toistuva haitallinen tilauskäyttäytyminen sulkee sinut suoraan työstä.",
    "ty1": "Varma",
    "ty2": "Peruuttaa",
    "ty3": "lähettää",
    "ty4": "osoite",
    "s1": "Kumulatiiviset tulot",
    "s2": "Palvella",
    "s3": "meistä",
    "s4": "auttaa",
    "s5": "Kumppanit",
    "s6": "Minun palveluni",
    "d1": "Ei valmis",
    "d2": "Valmis",
    "d3": "Ei vielä tietoja",
    "d4": "Yksi numero",
    "d5": "Kaupankäynti",
    "d6": "Määrä",
    "d7": "Nykyinen tehtävien lukumäärä",
    "d8": "Odotettavissa olevat ansiot",
    "d9": "Tarve",
    "d10": "pisteet",
    "j1": "Tilin saldo",
    "j2": "Kaupan esittely",
    "j3": "Tiktokshop käyttää Tiktokshop -palautetta joka päivä esitelläkseen Tiktokshopin myymiä tuotteita, jotka tarvitsevat parannusta. Käyttäjän on vain napsautettava tilausta ja järjestelmä luo tilauksen allekirjoituksen automaattisesti. Käyttäjät maksavat tilausmäärän USDT -blockchainin kautta ja saavat joka kerta yli 0,6% palkkion satunnaisesti suuria palkkiotilauksia.",
    "j4": "Aloita sovitus",
    "j5": "Tämän päivän ansiot",
    "j6": "Valmis numero",
    "j7": "Tehtävien kokonaismäärä",
    "td1": "Saldo",
    "td2": "komissio",
    "td3": "vastaanottaa",
    "td4": "Ihmisten kokonaismäärä",
    "td5": "käyttäjä",
    "td6": "osallistua",
    "td7": "määrä",
    "td8": "osavaltio",
    "g1": "Peruuttaminen",
    "g2": "täydentää",
    "g3": "Kutsua ystäviä",
    "g4": "Kutsu nyt",
    "g5": "Saat vastaavan osan komissiosta jokaisesta ansaitsemastasi voitosta ystävän rekisteröinnin jälkeen",
    "g6": "henkilötiedot",
    "g7": "Rahoitustiedot",
    "g8": "Lataustietue",
    "g9": "Nostotiedot",
    "g10": "kieli",
    "g11": "Kirjautua sisään",
    "g12": "Oletko varma, että kirjaudut ulos?",
    "yh1": "Käyttäjätiedot",
    "yh2": "Tarkistaa",
    "yh3": "asentaa",
    "yh4": "Puhelin",
    "yh6": "Alkuperäinen salasana",
    "yh7": "Uusi salasana",
    "mx1": "Saada komissiota",
    "mx2": "tulot",
    "mx3": "Nosto kieltäytyminen",
    "mx4": "aika",
    "mx5": "Lataus onnistuneesti",
    "mx6": "Hylätty",
    "mx7": "Odotettavissa oleva",
    "mx8": "Nostoosoite",
    "mx9": "Käytettävissä oleva saldo",
    "mx10": "Varsinainen kuitti",
    "mx11": "Anna nostosumma",
    "cz2": "kopioida",
    "cz3": "Filippiiniläisen valuutan valinta",
    "cz4": "Maksaa",
    "cz5": "Talletusosoite tukee vain TRC20-USDT: tä, ja vähimmäisvarasto on 20USDT.",
    "cz6": "Talletusosoite tukee vain ERC20: ta, vähimmäissumma on 20USDT",
    "cz1": "Talletusosoite tukee vain ERC20: ta, vähimmäissumma on 20USDT",
    "qy1": "Suuntanumero",
    "yq1": "Kutsua ystäviä",
    "yq2": "Kutsukoodi",
    "yq3": "Kutsu ystäviä ja nauti suurista voitoista",
    "yq4": "Mennä kutsumaan"
  }
  
//德语
export const de = {
    //Navigationsleiste
    'n1': 'Zuhause',
    'n2': 'Reihenfolge',
    'n3': 'Transaktion',
    'n4': 'Team',
    'n5': 'mein',
    //prompt-Klasse
    't1': 'Ein Fehler ist aufgetreten',
    't2': 'Kopieren erfolgreich',
    't3': 'Bitte füllen Sie die Informationen vollständig aus',
    't4': 'Laden...',
    't5': 'Anmeldung erfolgreich',
    't6': 'Die Punktzahl muss größer als 3 sein, um einzureichen',
    't7': 'Übermittlung erfolgreich',
    't8': 'Unzureichendes Guthaben, Empfang nicht möglich',
    't9': 'Übermittlung fehlgeschlagen',
    't10': 'Bitte binden Sie zuerst die Adresse ein, dann werden Sie zur persönlichen Center-Seite weitergeleitet',
    't11': 'Die Adresse wurde gebunden. Bitte wenden Sie sich an den Kundendienst, um sie zu ändern',
    't12': 'Änderung erfolgreich',
    't13': 'Änderung fehlgeschlagen',
    't14': 'Bitte wenden Sie sich an den Kundendienst, um Ihr Passwort zu ändern',
    't15': 'Passwortformatfehler',
    't16': 'Auszahlung erfolgreich',
    't17': 'Maximale Auszahlungen',
    't18': 'Mindestauszahlung',
    't19': 'Zwei Passwörter sind inkonsistent',
    't20': 'Eingabeaufforderung',
    't21': 'Kann vorübergehend nicht springen',
    't22': 'Passwortformatfehler',
    't23': 'Registrierung erfolgreich',
    't24': 'Bitte geben Sie die Vorwahl ein',
    't25': 'Nicht mehr',
    //Melden Sie sich an
    'l1': 'Sprache',
    'l2': 'Bitte geben Sie Ihre Kontonummer ein',
    'l3': 'Bitte Passwort eingeben',
    'l4': 'merken',
    'l5': 'Anmelden',
    'l6': 'Zur Registrierung gehen',
    'l7': 'Kein Konto',
    'l8': 'Mobiltelefonnummer',
    'l9': 'Spitzname',
    'l10': 'Passwort',
    'l11': 'Passwort bestätigen',
    'l12': 'Einladungscode',
    'l13': 'Registrieren',
    'l14': 'Haben Sie bereits ein Konto',
    'l15': 'Zum Anmelden gehen',
    'l16': 'Bitte geben Sie ein 6-16-stelliges Passwort ein',

    //helfen
    'bz1': 'Hilfe',
    'bz2': 'FAQ',
    'bz3': 'Handelsregeln',
    'bz4': 'So heben Sie Geld ab',
    'bz5': 'So laden Sie auf',
    'bz6': '1.Was ist TiktokShop?',
    'bz7': 'TiktokShop ist ein Marketing- und Werbeunternehmen, das Händlern im TiktokShop-Onlineshop auf der ganzen Welt dabei hilft, mehr Bestellverkäufe zu erzielen und die Browsing-Daten im TiktokShop-Onlineshop zu steigern. Wir sind bestrebt, ein dreigliedriges, profitables Marketing- und Werbemodell zwischen TiktokShop, Händlern und Verbrauchern zu etablieren. In Kombination mit der neuesten P2P-Blockchain-Technologie werden Verbraucher und Händler über USDT (TRC20, ERC20) schnell verbunden. Registrierte Benutzer können Provisionen für Bestellungen erhalten, während Händler die Verkaufsdaten ihres Shops steigern können. Es ist das neueste Gewinnmodell im Internet-Blockchain-Modell! ',
    'bz8': '2.Wie funktioniert TiktokShop?',
    'bz9': 'Das tägliche Feedback von TiktokShop zeigt, dass Produkte, die verbessert werden müssen, in TiktokShop verkauft werden. Benutzer müssen nur einfach auf die Bestellung klicken, und das System generiert automatisch ein Bestellabonnement. Benutzer zahlen den Bestellbetrag über die Blockchain USDT und erhalten tägliche Provisionen. ',
    'bz10': '3. Warum unterscheiden sich die Preise derselben Währung in zwei Transaktionen? ',
    "bz11": "Preisunterschiede werden durch alle Faktoren verursacht, die dem freien Währungsverkehr nicht förderlich sind, einschließlich der Geschwindigkeit von Währungstransfers, Netzwerkbedingungen, Währungszugangsbeschränkungen, Währungserkennung durch Menschen in verschiedenen Regionen und sogar den Handelspaaren.“ bereitgestellt durch Börsentypen, Transaktionen usw. Daher kann es bei derselben Währung bei verschiedenen Transaktionen zu Preisunterschieden kommen. ",
    'bz12': '4. Investitionsgewinn? ',
    'bz13': 'Je höher der Produktpreis, den Sie erzielen, desto höher ist der Auftragsgewinn, den Sie erzielen. Gleichzeitig verteilt TiktokShop große Provisionsaufträge nach dem Zufallsprinzip. ',
    'bz14': 'Jeden Tag generiert und verteilt das System automatisch 60 Produktbestellungen an Benutzer. Benutzer schließen die Zahlung für jede Bestellung über USDT ab und erhalten eine Provision von 0.6%. Große Kommissionsaufträge werden nach dem Zufallsprinzip vergeben. ',
    'bz15': 'USDT kann nach 60 Bestellungen ausgezahlt werden. (Wenn 60 Bestellungen nicht abgeschlossen sind, stoppt das System automatisch, bis die restlichen Bestellungen des Tages vorliegen)',
    'bz16': 'Jedes Konto kann nur an ein USDT-Konto gebunden werden. Wenn ein Fehler auftritt, wenden Sie sich bitte an den Kundendienst',
    "bz17": "Nach Abschluss der Lieferung der täglichen Bestellungen können Sie wie gewohnt Geld abheben. Es kommt innerhalb von 24 Stunden nach Beginn der Abhebung an und es gibt kein Abhebungslimit ",
    'bz18': 'Bitte binden Sie vor dem Aufladen Ihre Abhebungsadresse (unterstützt TRC-20) USDT-Adresse ein. Sie können in der APP oder auf der Webseite auf „Aufladen“ klicken und die Blockchain (TRC-20) auswählen, die Sie zum Aufladen verwenden. ',
   'bz19': "Das Aufladen oder Übertragen von Geld zwischen verbundenen Konten ist nicht erlaubt. Dies ist ein böswilliges Order-Grab-Verhalten und beeinträchtigt ernsthaft die Betriebsregeln der Plattform. Wenn ein solches Verhalten festgestellt wird, wird das System das Konto sofort einfrieren und die Arbeit disqualifizieren.",
    'bz20': 'Verknüpfte Konten müssen die Bestellaufgaben im Konto jedes Tages abschließen, bevor sie mit der Aktualisierung der Bestellaufgaben für den nächsten Tag fortfahren können. Wenn es eine nicht abgeschlossene Aufgabe zwischen den verknüpften Konten gibt, wird die Aktualisierung der Bestellaufgaben gestoppt bis alle Bestellaufgaben abgeschlossen sind. Bestellaufgabe aktualisieren',
    "bz21": "Aufladungen oder Übertragungen zwischen verknüpften Konten sind nicht zulässig. Dies ist ein böswilliges Order-Grabber-Verhalten und beeinträchtigt die Betriebsregeln der Plattform erheblich. Wenn ein solches Verhalten festgestellt wird, verhängt das System eine Geldstrafe für das Konto. Die erste Strafe beträgt 15 % des Kontoguthabens. Wiederholtes böswilliges Order-Grabber-Verhalten führt direkt zum Arbeitsausschluss.",
    //Modultext
    //Gebräuchliche Worte
    'ty1': 'OK',
    'ty2': 'Abbrechen',
    'ty3': 'Senden',
    'ty4': 'Adresse',
    //Titelseite
    's1': 'Kumuliertes Einkommen',
    's2': 'Dienst',
    's3': 'Über uns',
    's4': 'Hilfe',
    's5': 'Partner',
    's6': 'Mein Dienst',
    //Befehl
    'd1': 'Unvollendet',
    'd2': 'Vollständig',
    'd3': 'Noch keine Daten',
    'd4': 'ungerade Zahl',
    'd5': 'Handelszeit',
    'd6': 'Betrag',
    'd7': 'Aktuelle Anzahl an Aufgaben',
    'd8': 'Erwartete Rendite',
    'd9': 'Brauche mehr',
    'd10': 'Bewertung',
    //Handel
    'j1': 'Kontostand',
    'j2': 'Handelseinführung',
    'j3': 'TiktokShop nutzt täglich das Feedback von TiktokShop, um die von TiktokShop verkauften Produkte anzuzeigen, die verbessert werden müssen. Benutzer müssen nur auf die Bestellung klicken und das System generiert automatisch eine Bestellsignatur. Benutzer zahlen den Bestellbetrag über die USDT-Blockchain und können jedes Mal eine Provision von mehr als 0,6 % erhalten, und das System verteilt große Belohnungsaufträge nach dem Zufallsprinzip. ',
    'j4': 'Abgleich starten',
    'j5': 'Heutige Einnahmen',
    'j6': 'Nummer abgeschlossen',
    'j7': 'Gesamtanzahl der Aufgaben',
    //Team
    'td1': 'Balance',
    'td2': 'Kommission',
    'td3': 'Empfangen',
    'td4': 'Gesamtzahl der Personen',
    'td5': 'Benutzer',
    'td6': 'Beitrag',
    'td7': 'Menge',
    'td8': 'Status',
    //Persönliches Zentrum
    'g1': 'Zurückziehen',
    'g2': 'Aufladen',
    'g3': 'Freunde einladen',
    'g4': 'Jetzt einladen',
    'g5': 'Für jeden Gewinn, den Ihr Freund nach der Registrierung erzielt, erhalten Sie einen entsprechenden Provisionsanteil',
    'g6': 'Persönliche Informationen',
    'g7': 'Fondsdetails',
    'g8': 'Aufladedatensatz',
    'g9': 'Auszahlungsdatensatz',
    'g10': 'Sprache',
    'g11': 'Abmelden',
    'g12': 'Möchten Sie sich wirklich abmelden?',
    //Benutzerinformation
    'yh1': 'Benutzerinformationen',
    'yh2': 'Ändern',
    'yh3': 'Einstellung',
    'yh4': 'Telefon',
    'yh5': 'Möchten Sie sich wirklich abmelden?',
    'yh6': 'Ursprüngliches Passwort',
    'yh7': 'Neues Passwort',
    //Einzelheiten
    'mx1': 'Provisionseinzug',
    'mx2': 'Gewinn',
    'mx3': 'Auszahlung abgelehnt',
    'mx4': 'Zeit',
    'mx5': 'Aufladen erfolgreich',
    'mx6': 'Abgelehnt',
    'mx7': 'ausstehend',
    'mx8': 'Auszahlungsadresse',
    'mx9': 'Verfügbares Guthaben',
    'mx10': 'Tatsächliche Ankunft',
    'mx11': 'Bitte geben Sie den Auszahlungsbetrag ein',
    //nachfüllen
    'cz1': 'Die Einzahlungsadresse unterstützt nur ERC20 und der Mindesteinzahlungsbetrag beträgt 20USDT',
    'cz2': 'Kopieren',
    'cz3': 'Fiat-Währungsauswahl',
    'cz4': 'Zahlung',
    'cz5': 'Die Einzahlungsadresse unterstützt nur TRC20-USDT und der Mindesteinzahlungsbetrag beträgt 20USDT',
    'cz6': 'Die Einzahlungsadresse unterstützt nur ERC20 und der Mindesteinzahlungsbetrag beträgt 20USDT',
    'cz1': 'Die Einzahlungsadresse unterstützt nur ERC20 und der Mindesteinzahlungsbetrag beträgt 20USDT',
    'cz1': 'Die Einzahlungsadresse unterstützt nur ERC20 und der Mindesteinzahlungsbetrag beträgt 20USDT',
    //Bereich
    'qy1': 'Vorwahl',
    'yq1': 'Freunde einladen',
    'yq2': 'Einladungscode',
    'yq3': 'Freunde einladen und große Vorteile genießen',
    'yq4': 'Geh und lade ein',
}